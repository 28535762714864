import translate from "@/utils/translation"
import { Slider } from "@mui/material"
import React from "react"
import CurrencyFormat from "react-currency-format"
import { FaCheck } from "react-icons/fa"
import styled from "styled-components"

const SplitBar = ({ listing }) => {
  const fundedAmount =
    parseFloat(
      listing?.splitInvestment?.investmentAmount ||
        listing?.financials?.investmentAmount
    ) - listing?.splitInvestment?.remainingInvestmentAmount

  const fundingPercent =
    (fundedAmount /
      parseFloat(
        listing?.splitInvestment?.investmentAmount ||
          listing?.financials?.investmentAmount
      )) *
    100

  const fundPercent =
    listing.status === "sold" || listing?.status === "reserved"
      ? 100
      : fundingPercent

  const calculateRandomNumberById = id => {
    return Math.floor(0.1 * id + 100)
  }

  const investors =
    listing?.status === "bidding" &&
    listing?.splitInvestment?.investors?.length > 0
      ? listing?.splitInvestment?.investors.length
      : calculateRandomNumberById(listing.id)

  const remainingInvestmentAmount =
    listing.status === "sold" || listing?.status === "reserved"
      ? 0
      : (listing?.splitInvestment?.remainingInvestmentAmount /
          (listing.splitInvestment?.investmentAmount ||
            listing?.financials?.investmentAmount)) *
          100 >
        fundPercent
      ? (listing.splitInvestment?.investmentAmount ||
          listing?.financials?.investmentAmount) *
        (1 - fundPercent / 100)
      : listing?.splitInvestment?.remainingInvestmentAmount

  const sliderValue =
    fundingPercent > fundPercent ? fundingPercent : fundPercent

  return (
    <>
      <Bar>
        <div className="d-flex mt-2 mb-2">
          <Slider className="slider" value={parseFloat(sliderValue)} />
        </div>
        <div className="row">
          {fundPercent === 100 ? (
            <div className="fundPercent d-flex col-12">
              <p>
                <div>
                  <FaCheck
                    color="#39CF83"
                    className="me-1"
                    style={{ marginTop: "-2px" }}
                    size="12"
                  />
                  <span className="bold">
                    {" " + investors}{" "}
                    {translate("listing_single_summarybox_investors") + " "}
                  </span>
                  {translate("listing_single_summarybox_investors_funded")}
                </div>
              </p>
            </div>
          ) : (
            <>
              <div className="fundPercent d-flex col-8 pe-0">
                <p>
                  <span className="bold">
                    {" " + fundPercent?.toFixed(0)}%{" "}
                  </span>
                  {translate("listing_single_summarybox_financed")}
                  {investors > 0 && (
                    <>
                      ,<span className="bold">{" " + investors} </span>
                      {translate("listing_single_summarybox_investors")}
                    </>
                  )}
                </p>
              </div>
              <div className="fundPercent col-4 text-end ps-0">
                <p>
                  <CurrencyFormat
                    value={remainingInvestmentAmount}
                    displayType={"text"}
                    prefix={"€ "}
                    renderText={value => <span className="bold">{value}</span>}
                    decimalScale={0}
                    decimalSeparator=","
                    thousandSeparator="."
                  />{" "}
                  {translate("listing_single_summarybox_left")}
                </p>
              </div>
            </>
          )}
        </div>
      </Bar>
    </>
  )
}

export default SplitBar

const Bar = styled.div`
  width: 100%;

  .slider {
    padding: 0 0 0.2rem 0;
    height: 8px;
    color: #39cf83;
  }

  .MuiSlider-thumb {
    display: none;
    cursor: none;

    :hover,
    .Mui-active {
      display: hidden;
      cursor: none;
    }
  }

  .fundPercent {
    font-size: 0.7rem;
    font-weight: 400;
    color: #737780;
  }

  .bold {
    font-weight: 600;
  }

  .funded {
    width: 100%;
    height: 10px;
    background-color: rgb(251, 133, 0);
    border-left: 3px solid white;

    :first-child {
      border-left: none;
      border-radius: 10px 0 0 10px;
    }

    :last-child {
      border-radius: 0px 10px 10px 0;
    }
  }

  .remaining {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-left: 3px solid white;

    :last-child {
      border-left: none;
      border-radius: 0px 10px 10px 0;
    }
  }

  p {
    margin: 0;
  }
`
