import translate from "@/utils/translation"
import moment from "moment"
import { useSession } from "next-auth/react"
import React from "react"
import styled from "styled-components"

const ListingStatus = ({ listing }) => {
  const { data: session } = useSession()

  const calculateRemainingTime = sold => {
    let closingDate

    if (!session?.profile?.firstName) {
      // If no session profile first name, set a random expiration date 1-3 days in the future for non-sold listings
      closingDate = sold
        ? moment(listing?.splitInvestment?.expirationDate) // Sold 14 days ago
        : moment().add((listing?.id % 5) + 1, "days") // Fixed future date based on listing id, fluctuating between 1 and 7 days
    } else {
      // Default case when session profile first name is present
      closingDate = sold
        ? moment().subtract(14, "days") // Sold 14 days ago:
        : listing?.splitInvestment?.expirationDate
    }

    const eventDate = moment(closingDate)
    const currentDate = moment()
    const diffInDays = eventDate.diff(currentDate, "days")
    const diffInHours = eventDate.diff(currentDate, "hours")
    const diffInWeeks = eventDate.diff(currentDate, "weeks")
    const expiredInHours = currentDate.diff(eventDate, "hours")
    const expiredInDays = currentDate.diff(eventDate, "days")
    const expiredInWeeks = currentDate.diff(eventDate, "weeks")

    if (diffInWeeks < 0) {
      return `${expiredInWeeks} ${translate("listing_status_weeks_ago")}`
    } else if (diffInDays < 0) {
      return `${expiredInDays} ${translate("listing_status_days_ago")}`
    } else if (diffInDays === 0) {
      if (diffInHours < 0) {
        return `${expiredInHours} ${translate("listing_status_hours_ago")}`
      } else {
        return `${diffInDays} ${translate("listing_status_days")}`
      }
    } else if (diffInWeeks === 0) {
      return diffInDays < 7
        ? `${diffInDays} ${translate("listing_status_days")}`
        : `${diffInWeeks} ${translate("listing_status_weeks")}`
    } else if (diffInWeeks === 1) {
      return `${diffInWeeks} ${translate("listing_status_week")}`
    } else {
      return `${diffInWeeks} ${translate("listing_status_weeks")}`
    }
  }

  return (
    <Wrapper>
      <div
        className={
          listing.splitInvestment?.enabled ? "split status d-flex" : "status"
        }
      >
        {listing?.status === "bidding" && listing.splitInvestment?.enabled ? (
          <div className="open">{translate("listing_single_status_open")}</div>
        ) : listing?.status === "bidding" ? (
          <div className="forsale">
            {translate("listing_single_status_for_sale")}
          </div>
        ) : listing?.status === "sold" && listing.splitInvestment?.enabled ? (
          <div className="sold ">
            {translate("listing_single_status_fully_invested")}
          </div>
        ) : listing?.status === "sold" ? (
          <div className="sold">{translate("listing_single_status_sold")}</div>
        ) : listing?.status === "reserved" ? (
          <div className="reserved">
            {translate("listing_single_status_reserved")}
          </div>
        ) : null}
        {listing?.splitInvestment?.enabled && listing.status === "bidding" && (
          <div className="date">{calculateRemainingTime()}</div>
        )}
        {listing?.splitInvestment?.enabled && listing.status === "sold" && (
          <div className="date">{calculateRemainingTime(true)}</div>
        )}
      </div>
    </Wrapper>
  )
}

export default ListingStatus

const Wrapper = styled.div`
  /* Status styles */

  .status {
    text-align: center;
    font-weight: 600;
    font-size: 0.7rem;
    background: white;
    border-radius: 5px;

    .open {
      padding: 0.2rem 0.4rem 0.1rem;
      background: #39ce82;
      border-radius: 5px;
      color: white;
      font-size: 0.7rem;
      text-align: center;
      font-weight: 600;
      margin: 0;
    }

    .forsale {
      padding: 0.2rem 0.4rem;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      color: white;
      font-size: 0.7rem;
      text-align: center;
      font-weight: 600;
    }

    .sold {
      padding: 0.2rem 0.4rem;
      background: #565656;
      border-radius: 5px;
      color: white;
      text-transform: uppercase;
    }

    .reserved {
      padding: 0.2rem 0.4rem;
      background: #fb8501;
      border-radius: 5px;
      color: white;
      text-transform: uppercase;
    }
  }

  .split {
    padding: 0.2rem 0.4rem 0.2rem 0.2em;
    background: white;
    color: rgb(33, 47, 64);

    .date {
      margin-top: 0.17rem;
      margin-left: 0.5rem;
    }

    .sold {
      padding: 0.2rem 0.4rem 0.2rem 0.4em !important;
      border-radius: 5px;
      color: white;
      text-transform: uppercase;
    }
  }
`
